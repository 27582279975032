import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { faqItems } from './dummydata/FAQData.js';

const GiveFAQ = () => {
  const [openIndex, setOpenIndex] = useState(1);

  const toggleItem = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div  className="givepage-faq-container">
      <div className="givepage-faq-wrapper">
        <h2 className="givepage-faq-title">Questions? Look here.</h2>

        <div className="givepage-faq-items">
          {faqItems.map((item, index) => (
            <>
              <div key={index} className="givepage-faq-item">
                <button
                  onClick={() => toggleItem(index)}
                  className={`givepage-faq-button ${openIndex === index ? 'highlighted' : ''}`}
                  aria-expanded={openIndex === index}
                >
                  <span>
                    {item.question}
                    </span>
                  {openIndex === index ? (
                    <ChevronUp className="givepage-faq-icon" />
                  ) : (
                    <ChevronDown className="givepage-faq-icon" />
                  )}
                </button>
                <div className={`givepage-faq-content ${openIndex === index ? 'open' : ''}`}>{item.answer}</div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GiveFAQ;
