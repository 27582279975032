import React from 'react';

export const stats = [
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 45 42" fill="none">
        <path
          d="M42.8806 10.7656C42.4119 8.89062 40.9275 7.40625 39.1306 6.9375C35.7712 6 22.49 6 22.49 6C22.49 6 9.13061 6 5.77124 6.9375C3.97437 7.40625 2.48999 8.89062 2.02124 10.7656C1.08374 14.0469 1.08374 21.0781 1.08374 21.0781C1.08374 21.0781 1.08374 28.0312 2.02124 31.3906C2.48999 33.2656 3.97437 34.6719 5.77124 35.1406C9.13061 36 22.49 36 22.49 36C22.49 36 35.7712 36 39.1306 35.1406C40.9275 34.6719 42.4119 33.2656 42.8806 31.3906C43.8181 28.0312 43.8181 21.0781 43.8181 21.0781C43.8181 21.0781 43.8181 14.0469 42.8806 10.7656ZM18.115 27.4062V14.75L29.2088 21.0781L18.115 27.4062Z"
          fill="white"
        />
      </svg>
    ),
    value: '7.12M',
    label: 'YouTube Channel Views',
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 51 42" fill="none">
        <path
          d="M35.875 13.8125C36.7344 13.8125 37.4375 14.5156 37.4375 15.375V15.6875H42.125C42.9844 15.6875 43.6875 16.3906 43.6875 17.25C43.6875 18.1094 42.9844 18.8125 42.125 18.8125H41.9688L41.8125 19.2031C41.1094 21.0781 40.0156 22.875 38.6875 24.2812C38.7656 24.3594 38.8438 24.3594 38.9219 24.4375L40.4062 25.2969C41.1094 25.7656 41.3438 26.7031 40.9531 27.4844C40.4844 28.1875 39.5469 28.4219 38.7656 28.0312L37.2812 27.0938C36.9688 26.9375 36.5781 26.7031 36.2656 26.4688C35.4844 27.0156 34.5469 27.5625 33.6094 27.9531L33.375 28.1094C32.5938 28.4219 31.6562 28.1094 31.2656 27.3281C30.9531 26.4688 31.2656 25.6094 32.0469 25.2188L32.3594 25.1406C32.8281 24.9062 33.375 24.5938 33.8438 24.3594L32.8281 23.4219C32.2812 22.7969 32.2812 21.7812 32.8281 21.1562C33.4531 20.5312 34.4688 20.5312 35.0938 21.1562L36.1875 22.3281H36.2656C37.2031 21.3125 37.9844 20.1406 38.6094 18.8125H30.25C29.3125 18.8125 28.6875 18.1094 28.6875 17.25C28.6875 16.3906 29.3125 15.6875 30.25 15.6875H34.3125V15.375C34.3125 14.5156 34.9375 13.8125 35.875 13.8125ZM11.1875 22.5625L12.75 19.2812L14.2344 22.5625H11.1875ZM45.25 6C47.9844 6 50.25 8.26562 50.25 11V31C50.25 33.8125 47.9844 36 45.25 36H5.25C2.4375 36 0.25 33.8125 0.25 31V11C0.25 8.26562 2.4375 6 5.25 6H45.25ZM45.25 9.75H25.25V32.25H45.25C45.875 32.25 46.5 31.7031 46.5 31V11C46.5 10.375 45.875 9.75 45.25 9.75ZM14.1562 14.75C13.9219 14.2031 13.2969 13.8125 12.75 13.8125C12.125 13.8125 11.5 14.2031 11.2656 14.75L6.26562 26C5.95312 26.7812 6.26562 27.7188 7.04688 28.1094C7.82812 28.4219 8.76562 28.1094 9.15625 27.3281L9.85938 25.6875H15.5625L16.2656 27.3281C16.6562 28.1094 17.5938 28.4219 18.375 28.1094C19.1562 27.7188 19.4688 26.7812 19.1562 26L14.1562 14.75Z"
          fill="white"
        />
      </svg>
    ),
    value: '50',
    label: 'Languages Translated Into',
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 42" fill="none">
        <path
          d="M25 1C23.9062 1 23.125 1.85938 23.125 2.875V39.125C23.125 40.2188 23.9062 41 24.9219 41C25.8594 41 26.875 40.2188 26.875 39.125V2.875C26.875 1.85938 26.0156 1 25 1ZM17.5 8.5C16.4062 8.5 15.625 9.35938 15.625 10.375V31.625C15.625 32.7188 16.4062 33.5 17.4219 33.5C18.3594 33.5 19.375 32.7188 19.375 31.625V10.375C19.375 9.35938 18.5156 8.5 17.5 8.5ZM32.5 11C31.4062 11 30.625 11.8594 30.625 12.875V29.125C30.625 30.2188 31.4062 31 32.4219 31C33.3594 31 34.375 30.2188 34.375 29.125V12.875C34.375 11.8594 33.5156 11 32.5 11ZM40 6C38.9062 6 38.125 6.85938 38.125 7.875V34.125C38.125 35.2188 38.9062 36 39.9219 36C41.0156 36 41.875 35.2188 41.875 34.125V7.875C41.875 6.85938 41.0156 6 40 6ZM10 16C8.90625 16 8.125 16.8594 8.125 17.875V24.125C8.125 25.2188 8.90625 26 9.92188 26C10.8594 26 11.875 25.2188 11.875 24.125V17.875C11.875 16.8594 11.0156 16 10 16ZM2.5 18.5C1.40625 18.5 0.625 19.3594 0.625 20.375V21.625C0.625 22.7188 1.40625 23.5 2.42188 23.5C3.35938 23.5 4.375 22.7188 4.375 21.625V20.375C4.375 19.3594 3.51562 18.5 2.5 18.5ZM47.5 18.5C46.4062 18.5 45.625 19.3594 45.625 20.375V21.625C45.625 22.7188 46.4062 23.5 47.5 23.5C48.5156 23.5 49.375 22.7188 49.375 21.7031V20.375C49.375 19.3594 48.5156 18.5 47.5 18.5Z"
          fill="white"
        />
      </svg>
    ),
    value: '85K',
    label: 'Podcast Listens',
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 51 42" fill="none">
        <path
          d="M29.3486 15.7266H8.84863C6.79863 15.7266 5.125 17.3594 5.125 19.3672V34.3672C5.125 36.3672 6.79863 38 8.84863 38H29.3486C31.4066 38 33.0723 36.3672 33.0723 34.3672V19.3672C33.0803 17.3594 31.4066 15.7266 29.3486 15.7266ZM35.5077 22.5464C35.1641 22.714 34.9461 23.0628 34.9461 23.4451V31.2892C34.9461 31.6715 35.1641 32.0204 35.5077 32.188L44.6866 36.6655C45.3509 36.9896 46.125 36.5059 46.125 35.7668V18.9676C46.125 18.2285 45.3509 17.7448 44.6866 18.0689L35.5077 22.5464ZM10.7145 13.9062C13.8055 13.9062 16.3039 11.4609 16.3039 8.45312C16.3039 5.44531 13.8055 3 10.7145 3C7.63145 3 5.125 5.44531 5.125 8.45312C5.125 11.4609 7.63145 13.9062 10.7145 13.9062ZM27.4908 3C24.3998 3 21.9014 5.44531 21.9014 8.45312C21.9014 11.4609 24.4078 13.9062 27.4908 13.9062C30.5818 13.9062 33.0803 11.4609 33.0803 8.45312C33.0803 5.44531 30.5738 3 27.4908 3Z"
          fill="white"
        />
      </svg>
    ),
    value: '1000+',
    label: 'Videos Produced',
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 46 46" fill="none">
        <path
          d="M13.4167 32.5833H26.8333V28.75H13.4167V32.5833ZM13.4167 24.9167H32.5833V21.0833H13.4167V24.9167ZM13.4167 17.25H32.5833V13.4167H13.4167V17.25ZM9.58333 40.25C8.52917 40.25 7.62706 39.875 6.877 39.1249C6.12694 38.3749 5.75128 37.4721 5.75 36.4167V9.58333C5.75 8.52917 6.12567 7.62706 6.877 6.877C7.62833 6.12694 8.53044 5.75128 9.58333 5.75H36.4167C37.4708 5.75 38.3736 6.12567 39.1249 6.877C39.8763 7.62833 40.2513 8.53044 40.25 9.58333V36.4167C40.25 37.4708 39.875 38.3736 39.1249 39.1249C38.3749 39.8763 37.4721 40.2513 36.4167 40.25H9.58333ZM9.58333 36.4167H36.4167V9.58333H9.58333V36.4167Z"
          fill="#E8E8E8"
        />
      </svg>
    ),
    value: '400+',
    label: 'Original Articles',
  },
];
