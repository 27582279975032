import { useEffect, useState } from 'react';
import React from 'react';
import { stats } from './dummydata/StatsData.js';

const AnimatedStat = ({ icon, value, label }) => {
  const [animatedValue, setAnimatedValue] = useState(0);

  

  useEffect(() => {
    const targetValue =
      parseFloat(value.replace(/[^\d.-]/g, '')) * (value.includes('M') ? 1_000_000 : value.includes('K') ? 1_000 : 1);
    const duration = 2000;
    const frameRate = 60;
    const totalFrames = Math.round((duration / 1000) * frameRate);
    let frame = 0;

    const animate = () => {
      frame++;
      const progress = Math.min(frame / totalFrames, 1);
      setAnimatedValue(Math.floor(progress * targetValue));
      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    animate();
  }, [value]);

  const formatValue = (val) => {
    if (val >= 1_000_000) return `${(val / 1_000_000).toFixed(2)}M`;
    if (val >= 1_000) return `${(val / 1_000).toFixed(1)}K`;
    return val;
  };

  return (
    <div className="stat-item">
      <div className="stat-icon">{icon}</div>
      <div className="stat-value">{formatValue(animatedValue)} +</div>
      <div className="stat-label">{label}</div>
    </div>
  );
};

const StaticsData = () => {
  return (
    <div className="stats-container">
      {stats.map((stat, index) => (
        <AnimatedStat key={index} {...stat} />
      ))}
    </div>
  );
};

export default StaticsData;
