import { Link } from 'gatsby';
import React from 'react';

const ThankYouPageSection = () => {
  return (
    <div className="thank-you-page">
      <div className="thank-you-content">
        <h2>
          JOIN US AS WE SHARE THE STORIES OF CHRISTIAN ROLE MODELS FROM THE PAST TO INSPIRE, GUIDE AND SHAPE A
          GENERATION TO DISCOVER THEIR IDENTITY, PURPOSE AND MISSION
        </h2>

        <Link to="#givePage">GIVE</Link>

        <p>
          Lineage Journey is a registered 501(c)3 nonprofit organization. Thank you for making this possible. We would
          not be on this journey without you, together making a difference in the lives of many.
        </p>
      </div>
    </div>
  );
};

export default ThankYouPageSection;
