import { useState, useEffect } from 'react';

function useResponsiveBodyClass(defaultClass = 'body-dark', mobileClass = 'body-white', query = '(max-width: 768px)') {
  const [bodyClass, setBodyClass] = useState(defaultClass);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    const handleMediaChange = (e) => {
      if (e.matches) {
        setBodyClass(mobileClass);
      } else {
        setBodyClass(defaultClass);
      }
    };

    handleMediaChange(mediaQuery);

    mediaQuery.addEventListener('change', handleMediaChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaChange);
    };
  }, [defaultClass, mobileClass, query]);

  return bodyClass;
}

export default useResponsiveBodyClass;
