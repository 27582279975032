import React from 'react';

export function slideItemData(imageData) {
  return [
    {
      svg: (
        <svg className='playbtn-svg' xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" fill="none">
          <g filter="url(#filter0_b_15177_51843)">
            <path
              d="M80.8654 41.0001C80.8654 19.0531 63.0738 1.2616 41.1269 1.2616C19.1799 1.2616 1.38843 19.0531 1.38843 41.0001C1.38843 62.947 19.1799 80.7385 41.1269 80.7385C63.0738 80.7385 80.8654 62.947 80.8654 41.0001Z"
              fill="white"
              fill-opacity="0.8"
            />
          </g>
          <path
            d="M35.3515 28.1677C34.4644 27.6355 33.3409 27.6355 32.4539 28.1677C31.5669 28.6408 31.0347 29.587 31.0347 30.5923V51.4077C31.0347 52.4722 31.5669 53.4183 32.4539 53.8914C33.3409 54.4235 34.4644 54.3645 35.3515 53.8322L52.3823 43.4245C53.2101 42.9515 53.7424 42.0053 53.7424 41C53.7424 40.0538 53.2101 39.1077 52.3823 38.5754L35.3515 28.1677Z"
            fill="#191A1B"
          />
          <path
            d="M80.8654 41.0001C80.8654 19.0531 63.0738 1.2616 41.1269 1.2616C19.1799 1.2616 1.38843 19.0531 1.38843 41.0001C1.38843 62.947 19.1799 80.7385 41.1269 80.7385C63.0738 80.7385 80.8654 62.947 80.8654 41.0001Z"
            stroke="white"
            stroke-width="1.26154"
          />
          <defs>
            <filter
              id="filter0_b_15177_51843"
              x="-3.81157"
              y="-3.9384"
              width="89.8768"
              height="89.8769"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.6" />
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_15177_51843" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_15177_51843" result="shape" />
            </filter>
          </defs>
        </svg>
      ),

      image: imageData?.personStand?.childImageSharp.fluid,
      subTitle: 'New Video',
      title: `You Can Be Part </br> of What We Do`,
      content: 'Your generous support allows us to make biblical resources for people around the world.',
    },
    {
      image: imageData?.earthImg?.childImageSharp.fluid,
      subTitle: 'Latest Series',
      url:"/books/the-great-controversy",
      title: `The Great </br> Controversy`,
      content: 'The Great Controversy takes you on a journey through 2000 years of spiritual history.',
    },
    {
      image: imageData?.podcastImg?.childImageSharp.fluid,
      subTitle: 'Latest Podcast',
      title: `The History of </br> the Scriptures`,
      url:"/podcast/the-history-of-the-scriptures--doug-batchelor",
      content: `Doug Batchelor found a Bible in a cave, changing his life.  This episode explores its impact.`,
    },
    {
      image: imageData?.flagImage?.childImageSharp.fluid,
      subTitle: 'Lineage in your Language',
      title: `Language </br> Translations`,
      url:"/language-donations-page",
      content: `Lineage is taking advantage of the latest AI  translations </br>technology.`,
    },
    {
      image: imageData?.lastImage?.childImageSharp.fluid,
      subTitle: 'Upcoming Projects',
      title: `Lineage Journey In </br> 2025`,
      content: 'Upcoming series, resources, and projects from the Lineage Journey team.',
      url:"/donatepage",

    },
  ];
}
