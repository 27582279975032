import { Link } from 'gatsby';
import React from 'react';

function redirectToPayPal() {
  window.location.href = 'https://www.paypal.com/paypalme/LineageJourneyDonate';
}

export const faqItems = [
  {
    question: 'DO YOU ACCEPT CHECKS?',
    answer: (
      <ul className="givepage-faq-list">
        <li>
          Yes! Please feel free to send a check to us at the following address: <br />
          Lineage Journey<br />
          PO Box 405<br />
          Grass Valley,
          CA 95945
        </li>
      </ul>
    ),
  },
  {
    question: 'ARE THERE ANY OTHER METHODS FOR FINANCIAL GIVING?',
    answer: (
      <ul className="givepage-faq-list">
        <li>
          You can give through ACH direct <Link to="/contact">deposit.</Link>
        </li>
        <li>
          You can give through <a onClick={redirectToPayPal}>PayPal.</a>
        </li>
        <li>
          You can give by mailing a personal check to our address: <br />
          Lineage Journey<br />
          PO Box 405<br />
          Grass Valley,
          CA 95945
        </li>
      </ul>
    ),
  },
  {
    question: 'WHO CAN I TALK TO ABOUT A POTENTIAL GRANT OPPORTUNITY?',
    answer: (
      <ul className="givepage-faq-list">
        <li>
          To let us know about any grant funding opportunities, please <Link to="/contact">contact us</Link>
        </li>
      </ul>
    ),
  },
  {
    question: 'HOW DO I UPDATE MY MONTHLY GIVING?',
    answer: (
      <ul className="givepage-faq-list">
        <li>You can make changes to your giving by logging into your Lineagejourney account . </li>
      </ul>
    ),
  },
  {
    question: 'ARE GIFTS TO LINEAGE JOURNEY TAX-DEDUCTIBLE?',
    answer: (
      <ul className="givepage-faq-list">
        <li>Lineage Journey is a nonprofit 501(c)(3) organization and gifts made in the US are 100% tax-deductible</li>
      </ul>
    ),
  },
];
