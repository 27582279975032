import React, { useState } from 'react';

const PatronPerks = ({ pateronBookImg, travelMugImg }) => {
  const TabsData = [
    {
      id: 'monthlyFirstTab',
      label: '$10/month',
      description: 'Receive a physical copy of our annual magazine and a Lineage colouring book.',
      content: (
        <>
          <h3>Lineage Annual Magazine</h3>
          <p>
            Receive a physical copy of our annual magazine. A thoughtfully designed publication that looks back at the
            year in review and envisions our future goals.
          </p>
        
          <h3>Lineage Colouring Book</h3>
          <p>
            Receive a Lineage colouring book with original artwork, links to our videos and educational content.{' '}
            <span>Redeemable only once</span>, ships internationally.
          </p>
        </>
      ),
      imageKey: 'pateronBookImg', // Reference to dynamically load the image from props
      alt: 'Lineage Annual Magazine',
    },
    {
      id: 'monthlySecondTab',
      label: '$50/month',
      description: 'Receive a Lineage travel mug for supporters of at least $50/month.',
      content: (
        <>
          <h3>Lineage Travel Mug</h3>
          <p>
            This durable travel mug is perfect for taking drinks on the go or relaxing at home while watching your
            favorite Lineage video. This 25-ounce white enamel travel mug has a stainless steel insulated interior, and
            the Lineage logo on the side. It keeps hot drinks hot for up to 6 hours, and cold drinks cold for up to 8
            hours. It fits into most car cup holders and has a spill-proof plastic lid. Redeemable only once, ships
            internationally.
          </p>

          <h3>Lineage Annual Magazine</h3>
          <p>
            Receive a physical copy of our annual magazine. A thoughtfully designed publication that looks back at the
            year in review and envisions our future goals.
          </p>
          <h3>Lineage Colouring Book </h3>
          <p>
            Receive a Lineage colouring book with original artwork, links to our videos and educational content.
            Redeemable only once, ships internationally.
          </p>
        </>
      ),
      imageKey: 'travelMugImg',
      alt: 'Lineage Travel Mug',
    },
  ];
  const [activeTab, setActiveTab] = useState(TabsData[0].id);

  // Map image keys to props for dynamic loading
  const images = {
    pateronBookImg: pateronBookImg?.childImageSharp.fluid.src,
    travelMugImg: travelMugImg?.childImageSharp.fluid.src,
  };

  const activeTabData = TabsData.find((tab) => tab.id === activeTab);

  return (
    <div className="patronPerksSection">
      <h2>PATRON PERKS</h2>
      <div className="tab-container">
        {TabsData.map((tab) => (
          <button
            key={tab.id}
            className={activeTab === tab.id ? 'active' : 'inactive'}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      {activeTabData && (
        <div className="tab-content">
          <div className="content">{activeTabData.content}</div>
          <div className="image-container">
            <img src={images[activeTabData.imageKey]} alt={activeTabData.alt} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PatronPerks;
