import React from 'react';

const TotalBudgetSec = () => {
  return (
    <div className="budget-section">
      <div className="budget-container">
        <div className="budget-main">
          {/* Progress Circle */}
          <div className="progress-circle">
            <svg className="circle-svg" viewBox="0 0 100 100">
              {/* Background circle */}
              <circle cx="50" cy="50" r="45" className="circle-bg" />
              {/* Progress circle */}
              <circle cx="50" cy="50" r="45" className="circle-progress" />
            </svg>
            <div className="circle-content">
              <span className="percentage">81%</span>
              <span className="description">of every dollar goes to making and sharing content</span>
            </div>
          </div>

          {/* Stats and Text */}
          <div className="stats">
            <div className="text-content">
              <h2 className="title">YOUR KINDNESS ALLOWS THIS TO HAPPEN</h2>
              <p className="description">
                We are able to do this work because of your generous support. The most rewarding part? No one pays to
                watch, listen or read any of our online resources because they have already been funded by individuals
                like you.
              </p>
            </div>

            <div className="stats-grid">
              <div className="stat">
                <p className="stat-subtitle">TOTAL BUDGET</p>
                <h3 className="stat-value">$200,000</h3>
                <p className="stat-description">budget in 2023</p>
              </div>
              <div className="stat">
                <p className="stat-subtitle">TOTAL PATREONS</p>
                <h3 className="stat-value">350+</h3>
                <p className="stat-description">Patreons in 2023</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalBudgetSec;
