import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import BackgroundImage from 'gatsby-background-image';
import { slideItemData } from './dummydata/SlideItemData.js';
import { isBrowser } from '../../utils/isBrowser';
import { getItemsPos } from 'react-horizontal-scrolling-menu';
import { truncateText } from '../../utils/title-case.js';
import { Link } from 'gatsby';

const OwlCarousel = (() => {
  if (isBrowser()) {
    return require('react-owl-carousel');
  } else {
    return () => null;
  }
})();

const LearnmoreSec = ({ imageData, podCastData }) => {
  const slides = slideItemData(imageData);

  return (
    <div className="learn-more-main">
      <div className="learn-more-top">
        <h2>LEARN MORE ABOUT WHAT WE'RE UP TO</h2>
        <p>
          There's always a lot going on at Lineage. Here is what we're working on - made possible by your generous
          support.
        </p>
      </div>
      <OwlCarousel
        responsiveClass
        margin={6}
        dots={false}
        center={false}
        responsive={{
          0: { items: 1, margin: 10, stagePadding: 40, nav: false, center: false },
          500: { items: 2, margin: 10, nav: false, stagePadding: 40, center: false },
          768: { items: 3, nav: false, loop: false, center: false },
          1200: { items: 5, nav: false, center: false },
        }}
      >
        {slides.map((item, index) => {
          return (
            <Link to={item.url ? `${item.url}` : '#'}>
              <div key={index} className="slides-item">
                <BackgroundImage className="image-slide-give slide-give-overlay" Tag="section" fluid={item?.image}>
                  {item.svg && <div className="svg-wrapper">{item.svg}</div>}
                  <div className="learn-more-content text-white">
                    <div className="inner-content">
                      <p className="text-white">{item?.subTitle}</p>
                      <h2 className="text-white" dangerouslySetInnerHTML={{ __html: `${item?.title}` }}></h2>
                    </div>
                    <div className="inner-content-footer">
                      <p className="text-white" dangerouslySetInnerHTML={{ __html: `${item?.content}` }}></p>
                    </div>
                  </div>
                </BackgroundImage>
              </div>
            </Link>
          );
        })}
      </OwlCarousel>
    </div>
  );
};

export default LearnmoreSec;
