import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

const SupporterSection = ({ data, testimonials }) => {
  const cityImage = data.cityImage;
  const testimonialsDataGraph = testimonials.nodes;
  const [currentIndex, setCurrentIndex] = useState(6);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonialsDataGraph.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonialsDataGraph.length - 1 ? 0 : prevIndex + 1));
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="supporter-section-give body-dark">
      <div className="support-container">
        <h2>WHAT SUPPORTERS ARE SAYING</h2>
        <div className="support-content">
          <div className="support-text-area">
            <p className="testimonail-text">
              {testimonialsDataGraph[currentIndex]?.data?.description?.map((value, index) => (
                <span key={index}>{value.text}</span>
              ))}
            </p>
            <div className="author">
              <img src={testimonialsDataGraph[currentIndex]?.data?.featuredImage?.url} alt="Profile" />
              <div className="author-desc">
                <p className="name">{testimonialsDataGraph[currentIndex].data?.title[0]?.text}</p>
                <p className="title">{testimonialsDataGraph[currentIndex].data?.position[0]?.text}</p>
              </div>
            </div>
            <div className="footer-content">
              <div className="dots">
                {/* {testimonialsDataGraph.map((_, index) => ( */}
                  {/* <svg
                    key={"index"}
                    // onClick={() => handleDotClick(index)}
                    // aria-label={`Go to testimonial ${index + 1}`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="9"
                    viewBox="0 0 8 9"
                    fill="none"
                  >
                     <circle cx="4" cy="4.88574" r="4" fill={`${index === currentIndex ? 'black' : '#8D8D8D'}`} /> 
                    <circle cx="4" cy="4.88574" r="4" fill={`black`} />
                  </svg> */}
                {/* ))} */}
              </div>
               {/* <div className="navigation">
                <button>
                  {/* <ChevronLeft /> 
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Icon">
                      <path
                        id="Vector"
                        d="M20 11.8857H7.83L13.42 6.29574L12 4.88574L4 12.8857L12 20.8857L13.41 19.4757L7.83 13.8857H20V11.8857Z"
                        fill="black"
                      />
                    </g>
                  </svg>
                </button>
                <button>
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Icon">
                      <path
                        id="Vector"
                        d="M12 4.88574L10.59 6.29574L16.17 11.8857H4V13.8857H16.17L10.59 19.4757L12 20.8857L20 12.8857L12 4.88574Z"
                        fill="black"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            */} 
            </div>
          </div>
          <div className="image-area">
            <img src={cityImage?.childImageSharp?.fluid?.src} alt="Testimonial" />
          </div>
        </div>

        <div className="view-more">
          <Link className="navigate" to="/testimonial">
            VIEW MORE TESTIMONIALS
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SupporterSection;
