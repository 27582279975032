export const HeroSectionData = {
  title: 'On This Journey Together',
  description:
    'LINEAGE JOURNEY IS A CROWDFUNDED PROJECT. THANKS TO YOUR SUPPORT, WE ARE ABLE TO OFFER ALL THE DIGITAL RESOURCES WE CREATE FOR FREE AND HELP PEOPLE ALL OVER THE WORLD DISCOVER THEIR IDENTITY, PURPOSE, AND DESTINY.',
  progress: 32,
  monthlyGoalHeading: 'Monthly Goal Funded',
  monthlyGoalFunded: [
    {
      donationTitle: 'Online Donation',
      money: '340',
    },
    {
      donationTitle: 'Patreon',
      money: '140',
    },

    {
      donationTitle: 'Check',
      money: '23',
    },
  ],
  footerText: `Have questions? Want to give with a check? Visit our <span > <a href="#faq">FAQ</a></span>.`,
};
