import React, { useContext, useEffect, useState } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';
import GiveDonateForm from './DonateForm';
import { HeroSectionData } from './dummydata/HeroSecData';
import CustomDonations from '../Donation/CustomDonations/CustomDonations';
import CustomDonationsIndex from '../Donation/CustomDonations';
import DonorboxForm from './DonorForm';
import { LJCampaignUrl } from '../../constants/donorboxcampaign';
const HeroGiveSection = () => {
  return (
    <div className="hero-content ">
      <div className="hero-content-right">
        <h3 className="hero-content-title">{HeroSectionData?.title}</h3>
        <p className="hero-content-description">{HeroSectionData?.description}</p>
        <div className="hero-content-goal">
          <div className="progress-bar">
            <Box className="progress-box" position="relative" display="inline-flex">
              <CircularProgress
                variant="determinate"
                value={100}
                size={150}
                thickness={4}
                sx={{
                  color: '#FFFFFF',
                }}
              />
              {/* Inner Circle (Progress) */}
              <CircularProgress
                variant="determinate"
                value={HeroSectionData?.progress} // Dynamic value for inner circle
                size={150}
                thickness={4}
                sx={{
                  position: 'absolute',
                  color: '#FECB04', // Inner circle (progress color)
                }}
              />
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <span>{`${HeroSectionData?.progress}%`}</span>
              </Box>
            </Box>
          </div>

          <div className="monthy-goal-funded">
            <p>{HeroSectionData?.monthlyGoalHeading}</p>
            <div className="donation-data">
              {HeroSectionData?.monthlyGoalFunded?.map((value, index) => {
                return (
                  <div className="donation-details">
                    <p>{value.donationTitle}</p>
                    <p>{value.money}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="hero-content-left give-page">
        <div id='givePage' className="main-content ">
          {/* <GiveDonateForm projectsLists={projectsLists} defaultSelected={defaultSelected} /> */}
          <DonorboxForm src={LJCampaignUrl}/>
          {/* <CustomDonationsIndex
        
           projectsLists={projectsLists}
           defaultSelected={defaultSelected}  
          /> */}
        </div>
        <div className="footer-text">
          <p dangerouslySetInnerHTML={{ __html: HeroSectionData?.footerText }}></p>
        </div>
      </div>
    </div>
  );
};

export default HeroGiveSection;
